import { Injectable } from '@angular/core';
import { ApiDoctorsGeneral } from '@pushdr/doctors/data-access/doctors-api';
import { DoctorInfoService, TokenService } from '@pushdr/common/utils';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ZendeskService } from '@pushdr/zendesk';

@Injectable({
  providedIn: 'root',
})
export class CliniciansAuthService {
  loggedIn$ = new Subject<boolean>();
  loggedOut$ = new Subject<boolean>();

  email = '';
  password = '';

  constructor(
    private api: ApiDoctorsGeneral,
    private tokenService: TokenService,
    private doctorInfo: DoctorInfoService,
    private zendesk: ZendeskService
  ) {}

  missingAuthCredentials() {
    return this.email === '' || this.password === '';
  }

  sendSMSVerificationCode(email: string, password: string) {
    this.email = email;
    this.password = password;
    return this.api.sendLoginValidationCode(email, password);
  }

  hasAuth(): boolean {
    return this.tokenService.exists();
  }

  resendSMSVerificationCode() {
    return this.api.sendLoginValidationCode(this.email, this.password);
  }

  login(securityCode: string) {
    return this.api.loginCheck(this.email, this.password, securityCode).pipe(
      tap(result => {
        this.doctorInfo.set(result.DoctorClaim);
        this.doctorInfo.setPasswordResetNeeded(result.PasswordResetNeeded);
        this.tokenService.set(result.LoginCheckResult, null, null, moment().endOf('day').toDate());
        if (result.DoctorClaim.LastPasswordUpdate || !result.PasswordResetNeeded) {
          this.loggedIn$.next(true);
        }
      })
    );
  }

  logout() {
    this.deleteToken();
    this.zendesk.close();
    this.loggedOut$.next(true);
  }

  deleteToken() {
    this.doctorInfo.delete();
    this.tokenService.delete();
  }
}
