import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CliniciansAuthService,
  ConsultationStateService,
  ConsultationStorageService,
  ElevioService,
  NetworkMonitorService,
} from '@pushdr/clinicians/common';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  AnalyticsService,
} from '@pushdr/common/data-access/analytics';
import { RestErrorParserService } from '@pushdr/common/data-access/rest-http-core';
import { PDServerError } from '@pushdr/common/types';
import { ExtendedWindow, SystemCheckService, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ZendeskService } from '@pushdr/zendesk';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('attr.pdr-version')
  get pdrVersion(): string {
    return this.envProxy.environment.version;
  }

  constructor(
    private consultationStorage: ConsultationStorageService,
    private auth: CliniciansAuthService,
    private router: Router,
    private analytics: AnalyticsService,
    private msgBus: AnalyticsBusService,
    private errors: RestErrorParserService,
    private systemCheck: SystemCheckService,
    private consultationState: ConsultationStateService,
    private elevio: ElevioService,
    private networkMonitorService: NetworkMonitorService,
    private envProxy: EnvironmentProxyService,
    @Inject(WINDOW) private window: ExtendedWindow,
    private zendesk: ZendeskService
  ) {}

  ngOnInit() {
    this.systemCheck.initialise();
    this.networkMonitorService.initialise();
    this.guardAgainstNotSupported();
    if (this.auth.hasAuth()) {
      this.elevio.enable();
      this.loginZendesk();
    }

    this.auth.loggedOut$.subscribe({
      next: () => {
        this.elevio.disable();
        this.zendesk.logOut();
        this.navigateToLogin();
      },
    });

    this.auth.loggedIn$.subscribe({
      next: () => {
        this.consultationStorage.clearAllStorage();
        this.consultationState.clearConsultationState();
        this.elevio.enable();
        this.loginZendesk();
        this.router.navigate(['/home']);
      },
    });

    this.consultationStorage.onError().subscribe(err => {
      this.msgBus.trackEvent(AnalyticsEvent.error('consultation.storage.errors', err.toString()));
    });

    this.errors.errors$.pipe(delay(1)).subscribe((err: PDServerError) => {
      this.msgBus.trackEvent(
        AnalyticsEvent.error('app.error.log', err.message, { error: err.toString() })
      );
    });

    this.analytics.initialise('clinician-app');
    this.analytics.trackEvent(AnalyticsEvent.system('initialised', 'OK'));

    this.errors.errors$.pipe(delay(1)).subscribe((error: PDServerError) => {
      switch (error.status) {
        case 401:
          this.auth.logout();
          break;
      }
    });
  }

  navigateToLogin() {
    // clean the memory by hard reloading back to login view
    this.window.location.href = '/auth';
  }

  guardAgainstNotSupported() {
    if (!this.systemCheck.isChrome || this.systemCheck.isMobileDevice) {
      this.router.navigate(['not-supported']);
    }
  }

  private loginZendesk() {
    this.zendesk.logIn().subscribe({
      error: err => console.error('Failed to obtain JWT token for zendesk...\n', err),
    });
  }
}
