import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';
import { CliniciansAuthComponent } from './clinicians-auth.component';
import { CliniciansSignInComponent } from './routes/sign-in/sign-in.component';
import { CliniciansSecureMobileComponent } from './routes/secure-mobile/secure-mobile.component';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { TokenModule } from '@pushdr/common/utils';
import { HasNoAuthGuard } from './guards/has-no-auth.guard';
import { CliniciansNotSupportedComponent } from './routes/not-supported/not-supported.component';
import { CliniciansFirstTimeUpdatePasswordComponent } from './routes/first-time-update-password/first-time-update-password.component';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { ForgotPasswordComponent } from './routes/forgot-password/forgot-password.component';
import { CustomerServiceComponent } from './routes/customer-service/customer-service.component';
import { HasPasswordStoredGuard } from './guards/has-password-stored.guard';
import { ZendeskModule } from '@pushdr/zendesk';

export const CliniciansAuthRoutes: Route[] = [
  {
    path: 'auth',
    component: CliniciansAuthComponent,
    canActivate: [HasNoAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: CliniciansSignInComponent,
      },
      {
        path: 'secure-mobile',
        component: CliniciansSecureMobileComponent,
      },
      {
        path: 'update-password',
        component: CliniciansFirstTimeUpdatePasswordComponent,
        canActivate: [HasPasswordStoredGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'customer-service',
        component: CustomerServiceComponent,
      },
    ],
  },
  {
    path: 'not-supported',
    component: CliniciansNotSupportedComponent,
  },
];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CliniciansCommonModule,
    FormsModule,
    SpinnerLoaderModule,
    RouterModule.forChild(CliniciansAuthRoutes),
    TokenModule,
    ZendeskModule,
  ],
  declarations: [
    CliniciansAuthComponent,
    CliniciansSignInComponent,
    CliniciansSecureMobileComponent,
    CliniciansNotSupportedComponent,
    CliniciansFirstTimeUpdatePasswordComponent,
    ForgotPasswordComponent,
    CustomerServiceComponent,
  ],
})
export class CliniciansAuthModule {}
