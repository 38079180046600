import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { ZENDESK_PUBLIC_KEY } from './models';
import { ZendeskApiService } from './zendesk-api.service';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  statusChanges = new ReplaySubject<'opened' | 'closed'>(1);
  unreadMessages = new ReplaySubject<number>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ZENDESK_PUBLIC_KEY) private zendeskKey: string,
    private zendeskApiService: ZendeskApiService,
    private ngZone: NgZone
  ) {}

  async bootstrapWidgetSnippet(): Promise<void> {
    await this.attachWidgetSnippet();
    // Run zendesk events inside the angular zone
    zE?.('messenger:on', 'open', () => this.ngZone.run(() => this.statusChanges.next('opened')));
    zE?.('messenger:on', 'close', () => this.ngZone.run(() => this.statusChanges.next('closed')));
    zE?.('messenger:on', 'unreadMessages', (count: number) =>
      this.ngZone.run(() => this.unreadMessages.next(count))
    );
  }

  private attachWidgetSnippet(): Promise<void> {
    return new Promise(resolve => {
      const key = this.zendeskKey;
      const scriptUrl = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      const script = this.document.createElement('script');
      script.id = 'ze-snippet';
      script.src = scriptUrl;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => resolve();

      // Bootstraps the zendesk widget
      this.document.body.appendChild(script);
    });
  }

  logIn(): Observable<boolean> {
    this.logOut(); // <--- Clears up the cache before login
    return this.zendeskApiService.getZendeskChatToken().pipe(
      tap(jwtToken => this.loginWithJwt(jwtToken)),
      mapTo(true)
    );
  }

  private loginWithJwt(jwtToken: string): void {
    zE('messenger', 'loginUser', (callback: (jwt: string) => void) => {
      callback(jwtToken);
    });
  }

  logOut(): void {
    zE('messenger', 'logoutUser');
  }

  open(): void {
    zE('messenger', 'open');
  }

  close(): void {
    zE('messenger', 'close');
  }
}
