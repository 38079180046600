<ng-container [ngSwitch]="(statusChanges | async) ?? 'closed'">
  <svg
    *ngSwitchCase="'closed'"
    class="w-8 h-8"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Open Zendesk Chat">
    <path
      d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
      transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "></path>
  </svg>

  <ng-container *ngSwitchCase="'opened'">
    <span class="material-icons text-3xl" aria-label="Close Zendesk Chat">close</span>
  </ng-container>
</ng-container>

<div
  *ngIf="unreadMessages | async as counter"
  role="alert"
  class="absolute -top-8 -right-8 bg-red-500 w-6 h-6 rounded-full flex items-center justify-center"
  [@bounceOnEnter]>
  <div class="text-white font-bold text-base">
    {{ counter }} <span class="sr-only">Unread Messages</span>
  </div>
</div>
