<div [@showCard] class="pushdr-element c-sign-in__container">
  <div class="c-sign-in">
    <div class="c-sign-in__left-col">
      <div class="mb-8">
        <img
          style="max-width: 300px"
          aria-hidden="true"
          alt="Push doctor logo"
          src="/assets/images/logosvg/push-consult-logo.svg" />
      </div>
      <router-outlet></router-outlet>
    </div>
    <div class="c-sign-in__right-col">
      <img
        aria-hidden="true"
        alt="Illustration of doctor pointing"
        src="./assets/images/doctor-pointing.png" />
    </div>
  </div>
  <div class="fixed bottom-16 right-16 text-white">
    <button class="ze-icon" pushdrZendeskLauncher type="button"></button>
  </div>
</div>
