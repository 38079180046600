<button
  class="mt-20 menu-item superuser-tools-btn"
  [class.active]="activeMocks"
  [inlineSVG]="'/assets/icons/super-user.svg'"
  [replaceContents]="false"
  (click)="onShowDevTools()"
  *ngIf="devToolsEnabled"></button>

<nav
  [class.hidden]="!shownOnce"
  [@slideInLeft]="shownOnce"
  *ngVar="state$ | async as state"
  [class.isConsulting]="state !== ConsultationState.NOT_CONSULTING">
  <div class="logo">
    <img src="/assets/images/logosvg/push-doctor-shield-white.svg" alt="Logo home" />
  </div>
  <div class="menu mt-[20px]" *ngVar="features$ | async as features">
    <a
      class="menu-item"
      [routerLink]="['/home/dashboard']"
      routerLinkActive="active-link"
      tabindex="1"
      [inlineSVG]="'/assets/icons/dashboard.svg'"
      [replaceContents]="false">
      <span>Dashboard</span>
    </a>
    <a
      class="mt-8 menu-item"
      [routerLink]="['/consultation']"
      [class.active-link]="isConsulting(state)"
      tabindex="2"
      [inlineSVG]="'/assets/icons/consult-icon.svg'"
      [replaceContents]="false">
      <span>Consult</span>
    </a>
    <a
      class="mt-8 menu-item"
      (click)="editLast($event, features?.lastConEdit?.enabled)"
      *ngIf="features?.lastConEdit?.visible"
      [class.disabled]="!features?.lastConEdit?.enabled"
      [class.active-link]="state === ConsultationState.EDITING"
      tabindex="3"
      [inlineSVG]="'/assets/icons/last-consultation.svg'"
      [replaceContents]="false">
      <span>Edit last patient</span>
    </a>
    <a
      *ngIf="features?.consultationHistory?.visible"
      class="mt-8 menu-item"
      [routerLink]="['/home/consultationHistory']"
      routerLinkActive="active-link"
      tabindex="5"
      [inlineSVG]="'/assets/icons/consultation-history.svg'"
      [replaceContents]="false">
      <span>Consultation history</span>
    </a>
  </div>
  <div class="menu mt-auto">
    <div class="mb-4 menu-item" tabindex="5">
      <pushdr-network-status-indicator
        [networkStatus$]="networkStatus$"></pushdr-network-status-indicator>
      <pushdr-network-sub-menu
        class="sub-menu"
        style="bottom: -10px"
        [networkStatus$]="networkStatus$"
        (runTest)="runNetworkTest()">
      </pushdr-network-sub-menu>
    </div>
    <div class="mb-4 menu-item ze-icon" tabindex="6">
      <button pushdrZendeskLauncher type="button"></button>
      <span class="top-[10px]">Push Doctor Support</span>
    </div>
    <div class="mb-4 profile">
      <div class="menu-item profile-item" tabindex="7">
        <div
          class="w-12 h-12 m-auto bg-cover border-4 rounded-full"
          [style.backgroundImage]="profileImage$ | async"></div>
        <pushdr-profile-sub-menu class="sub-menu"></pushdr-profile-sub-menu>
      </div>
    </div>
  </div>
</nav>
