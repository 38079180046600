import { Injectable } from '@angular/core';
import { client as Elevio } from 'elevio';

import { ClinicianAnalyticsService } from '../analytics/clinician-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class ElevioService {
  constructor(private analytics: ClinicianAnalyticsService) {}

  enable() {
    Elevio.load('59e7669860f24');
    Elevio.enable();
  }

  disable() {
    Elevio.disable();
  }
}
