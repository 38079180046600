import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { bounceOnEnterAnimation } from 'angular-animations';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ZendeskService } from './zendesk.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[pushdrZendeskLauncher]',
  templateUrl: './zendesk-launcher.component.html',
  styleUrls: ['./zendesk-launcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [bounceOnEnterAnimation({ duration: 800 })],
})
export class ZendeskLauncherComponent {
  private isOpened = false;
  private status = new Subject<'opened' | 'closed'>();

  statusChanges = merge(this.zendesk.statusChanges, this.status).pipe(
    // Store the status locally to toggle the zendesk window
    tap(status => (this.isOpened = status === 'opened'))
  );

  unreadMessages = this.zendesk.unreadMessages;

  constructor(private zendesk: ZendeskService) {}

  @HostListener('click')
  onClick(): void {
    this.status.next(this.isOpened ? 'closed' : 'opened');
    this.zendesk[this.isOpened ? 'open' : 'close']?.();
  }
}
