import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InputWithIconModule, SpinnerLoaderModule } from '@pushdr/common/components';
import { InlineSVGModule } from 'ng-inline-svg';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CliniciansBulletinsModule } from './components/bulletins';
import { DiagnosisModule } from './components/diagnosis/diagnosis.module';
import { CardComponent } from './components/layout/card/card.component';
import { ConsultationCardComponent } from './components/layout/consultation-card/consultation-card.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { NetworkSubMenuComponent } from './components/navigation/network-sub-menu/network-sub-menu.component';
import { ProfileSubMenuComponent } from './components/navigation/profile-sub-menu/profile-sub-menu.component';
import { SidebarMenuComponent } from './components/navigation/sidebar-menu/sidebar-menu.component';
import { TopbarSteppedProgressComponent } from './components/navigation/topbar-stepped-progress/topbar-stepped-progress.component';
import { NetworkStatusIndicatorComponent } from './components/network-status-indicator/network-status-indicator.component';
import { ClinicianSystemPipe } from './pipes/clinician-system.pipe';
import { EditConsultationWarningComponent } from './components/navigation/sidebar-menu/edit-consultation-warning/edit-consultation-warning.component';
import { ScrollModule, VarModule } from '@pushdr/common/utils';
import { DevToolsComponent } from './components/navigation/sidebar-menu/dev-tools/dev-tools.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardReaderErrorModalComponent } from './services/card-reader/modals/card-reader-error/card-reader-error-modal.component';
import { LoadingModalComponent } from './services/card-reader/modals/loading/loading-modal.component';
import { CliniciansPasswordInputComponent } from './components/clinicians-password-input/clinicians-password-input.component';
import { PasswordValidationTooltipComponent } from './components/clinicians-password-input/password-validation-tooltip/password-validation-tooltip.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PasswordInputTooltipComponent } from './components/password-input-tooltip/password-input-tooltip.component';
import { PasswordInputWithRulesComponent } from './components/password-input-with-rules/password-input-with-rules.component';
import { ZendeskModule } from '@pushdr/zendesk';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InputWithIconModule,
    SpinnerLoaderModule,
    CliniciansBulletinsModule,
    DiagnosisModule,
    InlineSVGModule.forRoot(),
    VarModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollModule,
    ZendeskModule,
  ],
  declarations: [
    SidebarMenuComponent,
    NetworkSubMenuComponent,
    NetworkStatusIndicatorComponent,
    ProfileSubMenuComponent,
    TopbarSteppedProgressComponent,
    CardComponent,
    ConsultationCardComponent,
    AccordionComponent,
    PasswordValidationTooltipComponent,
    ListItemComponent,
    ClinicianSystemPipe,
    EditConsultationWarningComponent,
    DevToolsComponent,
    CardReaderErrorModalComponent,
    LoadingModalComponent,
    CliniciansPasswordInputComponent,
    PasswordInputComponent,
    PasswordInputTooltipComponent,
    PasswordInputWithRulesComponent,
  ],
  exports: [
    SidebarMenuComponent,
    ProfileSubMenuComponent,
    NetworkSubMenuComponent,
    NetworkStatusIndicatorComponent,
    TopbarSteppedProgressComponent,
    CardComponent,
    ConsultationCardComponent,
    CliniciansBulletinsModule,
    AccordionComponent,
    CliniciansPasswordInputComponent,
    ListItemComponent,
    ClinicianSystemPipe,
    PasswordInputComponent,
    PasswordInputTooltipComponent,
    PasswordInputWithRulesComponent,
  ],
})
export class CliniciansCommonModule {}
