import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { zendeskInitializer, zendeskKeyProvider } from './providers';
import { ZendeskLauncherComponent } from './zendesk-launcher.component';
import { ZendeskDirective } from './zendesk.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ZendeskDirective, ZendeskLauncherComponent],
  exports: [ZendeskDirective, ZendeskLauncherComponent],
})
export class ZendeskModule {
  static forRoot(): ModuleWithProviders<ZendeskModule> {
    return {
      ngModule: ZendeskModule,
      providers: [zendeskKeyProvider, zendeskInitializer],
    };
  }
}
